import React, { useState, useEffect} from 'react';
import axios from 'axios';
import {harbourArrivalsStyle} from "../style/harbourArrivalsStyle";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Mail2U from '../components/mail2U';
import ShareLinks from '../components/ShareLinks';

const HarboursArrivals = () => {
  const [data, setData] = useState([]);
  const [arrivalsArr, setArrivals] = useState([]);
  const [departuresArr, setDepartues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const showOnMobile = useMediaQuery('(max-width:821px)');
  const showOnDeskTop = useMediaQuery('(min-width:821px)');
  
  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)
      try {
        const result = await axios(process.env.GATSBY_HARBOURS_API)
        setData(result.data)
        setArrivals(result.data.Arrivals)
        setDepartues(result.data.Departures)
      } catch (error) {
        setIsError(true)
        console.log(error);
      }
        setIsLoading(false)
    }
      fetchData();
  },[])

  return (
    <React.Fragment>
    <div  css={harbourArrivalsStyle}>
    {isLoading && <span>Loading data</span> }
    {isError && <span>Error retrieving data please try again later</span>}
    
    {showOnDeskTop &&
    <React.Fragment>
    <div  className="arrdepHeader">
        <h2>Arrivals</h2>
    </div>
    <h3>This information was last updated {data.UpdatedTime}</h3>
    <p>Times for {data.TodayName}</p>
    <table className="arr">
    <tbody><tr>
    <td className="arrdep" width="23%">
            Vessel
        </td>
    <td className="arrdep" width="23%">
            Route
        </td>
    <td className="arrdep" width="20%">
            Quay
        </td>
        <td className="arrdep" width="8%">
            STA
        </td>
        <td className="arrdep" width="8%">
            ETA
        </td>
    <td className="arrdep" width="18%">
            Status
        </td>
    </tr>
        {arrivalsArr.map((result, index) => 
         result.today && 
          <tr key={index} className="arrdepd">
              <td>{result.vessel}</td>
              <td>{result.route}</td>
              <td>{result.quay}</td>
              <td>{result.scheduledTime}</td>
              <td>{result.expected.substring(result.expected.indexOf(' ') + 1)}</td>
              <td>{result.statusText}</td>
          </tr>
        )}
    </tbody></table>
    <br />
    <p>Times for {data.TomorrowName}</p>
    <table className="arr">
    <tbody><tr>
    <td className="arrdep" width="23%">
            Vessel
        </td>
    <td className="arrdep" width="23%">
            Route
        </td>
    <td className="arrdep" width="20%">
            Quay
        </td>
    <td className="arrdep" width="8%">
            STA
        </td>
        <td className="arrdep" width="8%">
            ETA
        </td>
    <td className="arrdep" width="18%">
            Status
        </td>
    </tr>
        {arrivalsArr.map((result, index) => 
          !result.today && 
          <tr key={index} className="arrdepd">
              <td>{result.vessel}</td>
              <td>{result.route}</td>
              <td>{result.quay}</td>
              <td>{result.scheduledTime}</td>
              <td>{result.expected.substring(result.expected.indexOf(' ') + 1)}</td>
              <td>{result.statusText}</td>
          </tr>
        )}
    </tbody></table>

 
    <div className="depHeader">
        <h2>Departures</h2>
    </div>
    <p>Times for {data.TodayName}</p>
    <table className="arr">
    <tbody><tr>
    <td className="arrdep" width="23%">
            Vessel
        </td>
    <td className="arrdep" width="23%">
            Route
        </td>
    <td className="arrdep" width="20%">
            Quay
        </td>
    <td className="arrdep" width="8%">
            STD
        </td>
        <td className="arrdep" width="8%">
            ETD
        </td>
    <td className="arrdep" width="18%">
            Status
        </td>
    </tr>
        {departuresArr.map((result, index) => 
        result.today && 
          <tr key={index} className="arrdepd">
              <td>{result.vessel}</td>
              <td>{result.route}</td>
              <td>{result.quay}</td>
              <td>{result.scheduledTime}</td>
              <td>{result.expected.substring(result.expected.indexOf(' ') + 1)}</td>
              <td>{result.statusText}</td>
          </tr>
        )}
    </tbody></table>
          <br />
    <p>Times for {data.TomorrowName}</p>
    <table className="arr">
    <tbody><tr>
    <td className="arrdep" width="23%">
            Vessel
        </td>
    <td className="arrdep" width="23%">
            Route
        </td>
    <td className="arrdep" width="20%">
            Quay
        </td>

    <td className="arrdep" width="8%">
            STD
        </td>
        <td className="arrdep" width="8%">
            ETD
        </td>
    <td className="arrdep" width="18%">
            Status
        </td>
    </tr>
        {departuresArr.map((result, index) => 
        !result.today && 
          <tr key={index} className="arrdepd">
              <td>{result.vessel}</td>
              <td>{result.route}</td>
              <td>{result.quay}</td>
              <td>{result.scheduledTime}</td>
              <td>{result.expected.substring(result.expected.indexOf(' ') + 1)}</td>
              <td>{result.statusText}</td>
          </tr>
        )}
    </tbody></table>
    </React.Fragment>
}

    {showOnMobile &&
    <React.Fragment>
    <div  className="arrdepHeader">
      <h2>Arrivals</h2>
      </div>
      <h3>This information was last updated {data.UpdatedTime}</h3>
      <div class="arrdep-mobile arrdep-harbour">
      <p>Times for {data.TodayName}</p>

      {arrivalsArr.map((result, index) => 
        result.today && 
        <div key={index} class="row">
          <dl>
            <dt>Vessel</dt>
            <dd>{result.vessel}</dd>
            <dt>Route</dt>
            <dd>{result.route}</dd>
            <div class="clear"></div>
            <dt>Quay</dt>
            <dd>{result.quay}</dd>
            <dt>STA</dt>
            <dd>{result.scheduledTime}</dd>
            <div class="clear"></div>
            <dt>ETA</dt>
            <dd>{result.expected.substring(result.expected.indexOf(' ') + 1)}</dd>
            <dt>Status</dt>
            <dd>{result.statusText}</dd>
            <dt>
            
            </dt>
          </dl>
        </div>
        )}

      </div>
      <br />
      <div class="arrdep-mobile arrdep-harbour">
      <p>Times for {data.TomorrowName}</p>

      {arrivalsArr.map((result, index) => 
        !result.today && 
        <div key={index} class="row">
          <dl>
            <dt>Vessel</dt>
            <dd>{result.vessel}</dd>
            <dt>Route</dt>
            <dd>{result.route}</dd>
            <div class="clear"></div>
            <dt>Quay</dt>
            <dd>{result.quay}</dd>
            <dt>STA</dt>
            <dd>{result.scheduledTime}</dd>
            <div class="clear"></div>
            <dt>ETA</dt>
            <dd>{result.expected.substring(result.expected.indexOf(' ') + 1)}</dd>
            <dt>Status</dt>
            <dd>{result.statusText}</dd>
            <dt>
              <p></p>
            </dt>
          </dl>
        </div>
        )}

      </div>
      <div  className="arrdepHeader">
      <h2>Departures</h2>
      </div>
    
      <div class="arrdep-mobile arrdep-harbour">
      <p>Times for {data.TodayName}</p>

      {departuresArr.map((result, index) => 
        result.today && 
        <div key={index} class="row">
          <dl>
            <dt>Vessel</dt>
            <dd>{result.vessel}</dd>
            <dt>Route</dt>
            <dd>{result.route}</dd>
            <div class="clear"></div>
            <dt>Quay</dt>
            <dd>{result.quay}</dd>
            <dt>STD</dt>
            <dd>{result.scheduledTime}</dd>
            <div class="clear"></div>
            <dt>ETD</dt>
            <dd>{result.expected.substring(result.expected.indexOf(' ') + 1)}</dd>
            <dt>Status</dt>
            <dd>{result.statusText}</dd>
            <dt>
              <p></p>
            </dt>
          </dl>
        </div>
        )}

      </div>
        <br />
      <div class="arrdep-mobile arrdep-harbour">
      <p>Times for {data.TomorrowName}</p>

      {departuresArr.map((result, index) => 
        !result.today && 
        <div key={index} class="row">
          <dl>
            <dt>Vessel</dt>
            <dd>{result.vessel}</dd>
            <dt>Route</dt>
            <dd>{result.route}</dd>
            <div class="clear"></div>
            <dt>Quay</dt>
            <dd>{result.quay}</dd>
            <dt>STD</dt>
            <dd>{result.scheduledTime}</dd>
            <div class="clear"></div>
            <dt>ETD</dt>
            <dd>{result.expected.substring(result.expected.indexOf(' ') + 1)}</dd>
            <dt>Status</dt>
            <dd>{result.statusText}</dd>
            <dt>
              <p></p>
            </dt>
          </dl>
        </div>
        )}

      </div>

    </React.Fragment>
}
    
    {showOnMobile && <Mail2U />}
    </div>

    </React.Fragment>  
  )
}

export default HarboursArrivals;