import { css } from "@emotion/core"
import arrive1 from "../images/arrive1.gif"
import depart1 from "../images/depart1.gif"
import icon_messenger from "../images/Messenger_Logo_Color_RGB.svg"
import icon_tweeter from "../images/Twitter_Logo_Blue.svg"
export const harbourArrivalsStyle = css`
.bt_mme_url,.bt_tweet_img{
    width:20px;
    height:20px;
    display:block;
    @media (max-width:820px) 
    {
        width:25px;
        height:25px;
        display:inline-block;
    }
}
.bt_mme_url{
    background: transparent url(${icon_messenger}) no-repeat; 
}
.bt_tweet_img{
    background: transparent url(${icon_tweeter}) no-repeat;
    background-position-y: 2px;
}
.arr .arrdep {
    padding: 5px 0;
    padding-left: 8px;
    background: #084369;
    font-size: 1em;
    color: #FFFFFF;
    line-height: 25px;
    vertical-align: bottom;
    font-weight: bold;
}

table.arr {
    padding: 0;
    width: 100%;
    border: none;
    border-collapse: collapse;
}

    table.arr .arrdep {
        padding: 5px 0;
        padding-left: 8px;
        background: #084369;
        font-size: 1em;
        color: #FFFFFF;
        line-height: 25px;
        vertical-align: bottom;
        font-weight: bold;
    }

    table.arr .arrdepd {
        font-size: 1em;
    }

        table.arr .arrdepd:nth-child(even) td {
            background: #E1F2FB;
        }

        table.arr .arrdepd td {
            padding: 0 8px;
        }
        table.arr .arrdepd td:not(.notify) {
            padding-top: 3px;
            padding-bottom: 3px;
        }

.arrdepHeader {
    margin-top: 3px;
    color: #004469;
    background: transparent url(${arrive1}) no-repeat 0px 0px;
    padding-left: 28px;
}

.depHeader {
    margin-top: 3px;
    color: #004469;
    background: transparent url(${depart1}) no-repeat 0px 0px;
    padding-left: 28px;
}
.arrdep-desktop {
    display: none;
}

.arrdep-mobile > p {
    background: #004269;
    color: #FFFFFF;
    padding: 12px;
    font-weight: bold;
    margin: 0;
}

.arrdep-mobile .row {
    padding: 12px;
}

    .arrdep-mobile .row:nth-child(even) {
        background: #E1F2FB;
    }

.arrdep-mobile .clear {
    clear: both;
}

.arrdep-mobile dl {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0
}

.arrdep-mobile dt {
    float: left;
    font-weight: bold;
    width: 25%;
    padding: 0;
    margin: 0 0 5px 0;
    overflow: hidden;
}

.arrdep-mobile dd {
    float: left;
    width: 75%;
    padding: 0;
    padding-right: 10px;
    margin: 0 0 5px 0;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
}

.arrdep-mobile .bt_mme_url {
    margin-right: 24px;
}

.arrdep-mobile .m-empty {
    display: none;
}

.arrdep-mobile .row,
.arrdep-mobile > p,
table.arr tr {
    border-bottom: 1px dashed #9DC0E9;
}

.airportarrivedepart > h3 {
    margin-bottom: 1em;
}

.arrdep-harbour {
    margin-top: 1em;
}

@media (min-width: 410px) {
    .arrdep-mobile dt {
        width: 25%;
    }

    .arrdep-mobile dd {
        width: 25%;
    }

    .arrdep-mobile .m-empty {
        display: block;
    }

    .arrdep-harbour .bt_mme_url,
    .arrdep-harbour .bt_tweet_img {
        margin-top: 0;
    }
}

@media (min-width: 520px) {
    .arrdep-mobile dt {
        width: 20%;
    }

    .arrdep-mobile dd {
        width: 30%;
    }
}

@media (min-width: 660px) {
    .arrdep-mobile dt {
        width: 10%;
    }

    .arrdep-mobile dd {
        width: 40%;
    }
}

@media (min-width: 821px) {
    .arrdep-mobile {
        display: none;
    }
    .arrdep-desktop {
        display: block;
    }
} 


`